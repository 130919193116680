import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/index.less';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import VueAnimateNumber from 'vue-animate-number';

Vue.use(VueAwesomeSwiper);
Vue.use(VueAnimateNumber);
Vue.use(ViewUI);
Vue.config.productionTip = false;


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')