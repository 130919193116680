const utils = {};

utils.trimLR = (str) => { //删除左右两端的空格
    return (str || '').replace(/(^\s*)|(\s*$)/g, '');
}

utils.scrollTo = () => { //滚动到顶部
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
    });
}

utils.numberToCurrencyNo = (value) => {
    // num = num.toString().split("."); // 分隔小数点
    // var arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
    // var res = [];
    // for (var i = 0, len = arr.length; i < len; i++) {
    //     if (i % 3 === 0 && i !== 0) {
    //         res.push(","); // 添加分隔符
    //     }
    //     res.push(arr[i]);
    // }
    // res.reverse(); // 再次倒序成为正确的顺序
    // if (num[1]) {
    //     // 如果有小数的话添加小数部分
    //     res = res.join("").concat("." + num[1]);
    // } else {
    //     res = res.join("");
    // }
    // return res;
    if (!value) return 0
        // 获取整数部分
    const intPart = Math.trunc(value)
        // 整数部分处理，增加,
    const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        // 预定义小数部分
    let floatPart = ''
        // 将数值截取为小数部分和整数部分
    const valueArray = value.toString().split('.')
    if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
    }
    return intPartFormat + floatPart

}

utils.downloadFile = (url, name) => {
    var x = new XMLHttpRequest();
    //禁止浏览器缓存；否则会报跨域的错误
    x.open("GET", url + '?t=' + new Date().getTime(), true);
    x.responseType = 'blob';
    x.send();
    x.onload = () => {
        var reader = new FileReader();
        reader.readAsDataURL(x.response);
        reader.onload = function() {
            var src = window.URL.createObjectURL(x.response);
            var a = document.createElement('a');
            a.href = src;
            a.download = name;
            a.click();
        };
    }
}

utils.getYear = () => { //获取当前年份
    var date = new Date;
    var year = date.getFullYear();
    return year;
}
export default utils;