import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// 避免到当前位置的冗余导航(重复触发了同一个路由)解决办法
const original = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return original.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'Index',
        component: () =>
            import ('@/views/index.vue'),
        redirect: '/home',
        children: [{
                path: '/home',
                name: 'Home',
                component: () =>
                    import ('@/views/Home.vue'),
            },
            {
                path: '/company_introduction',
                name: 'AboutUs',
                component: () =>
                    import ('@/views/about/AboutUs.vue'),
            },
            {
                path: '/main_business',
                name: 'MainBusiness',
                component: () =>
                    import ('@/views/business/MainBusiness.vue'),
            },
            {
                path: '/industry_case',
                name: 'IndustryCase',
                component: () =>
                    import ('@/views/industry/IndustryCase.vue'),
            },
            {
                path: '/news',
                name: 'News',
                component: () =>
                    import ('@/views/news/News.vue'),
            },
            {
                path: '/company_news/:id',
                name: 'NewsChild',
                component: () =>
                    import ('@/views/news/NewsChild.vue'),
            },
            {
                path: '/industry_trends/:id',
                name: 'NewsChild',
                component: () =>
                    import ('@/views/news/IndustryTrendsChild.vue'),
            },
            {
                path: '/join_us',
                name: 'JoinUs',
                component: () =>
                    import ('@/views/join/JoinUs.vue'),
            },
            {
                path: '/contact_us',
                name: 'ContactUs',
                component: () =>
                    import ('@/views/contact/ContactUs.vue'),
            },
            {
                path: '/operating_terms',
                name: 'OperatingTerms',
                component: () =>
                    import ('@/views/policy/OperatingTerms.vue'),
            },
            {
                path: '/legal_notices',
                name: 'LegalNotices',
                component: () =>
                    import ('@/views/policy/LegalNotices.vue'),
            }
        ]
    },
    {
        path: '/indexEn',
        name: 'IndexEn',
        component: () =>
            import ('@/views/index.vue'),
        redirect: '/home_en',
        children: [{
                path: '/home_en',
                name: 'HomeEn',
                component: () =>
                    import ('@/views/HomeEn.vue'),
            },
            {
                path: '/company_introduction_en',
                name: 'AboutUs',
                component: () =>
                    import ('@/views/about/AboutUsEn.vue'),
            },
            {
                path: '/main_business_en',
                name: 'MainBusiness',
                component: () =>
                    import ('@/views/business/MainBusinessEn.vue'),
            },
            {
                path: '/industry_case_en',
                name: 'IndustryCase',
                component: () =>
                    import ('@/views/industry/IndustryCaseEn.vue'),
            },
            {
                path: '/news_en',
                name: 'News',
                component: () =>
                    import ('@/views/news/NewsEn.vue'),
            },
            {
                path: '/company_news_en/:id',
                name: 'NewsChild',
                component: () =>
                    import ('@/views/news/NewsChildEn.vue'),
            },
            {
                path: '/industry_trends_en/:id',
                name: 'NewsChild',
                component: () =>
                    import ('@/views/news/IndustryTrendsChildEn.vue'),
            },
            {
                path: '/join_us_en',
                name: 'JoinUs',
                component: () =>
                    import ('@/views/join/JoinUsEn.vue'),
            },
            {
                path: '/contact_us_en',
                name: 'ContactUs',
                component: () =>
                    import ('@/views/contact/ContactUsEn.vue'),
            },
            {
                path: '/operating_terms_en',
                name: 'OperatingTerms',
                component: () =>
                    import ('@/views/policy/OperatingTermsEn.vue'),
            }
        ]
    },
    // {
    //     path: '/rx_home',
    //     name: 'RongXinIndex',
    //     component: () =>
    //         import ('@/views/other/RongXinIndex.vue'),
    //     redirect: '/rx_home',
    //     children: [{
    //         path: '/rx_home',
    //         name: 'RongXinHome',
    //         component: () =>
    //             import ('@/views/other/RongXinHome.vue'),
    //     }, ]
    // },
    // {
    //     path: '/rx_home_en',
    //     name: 'RongXinIndex',
    //     component: () =>
    //         import ('@/views/other/RongXinIndex.vue'),
    //     redirect: '/rx_home_en',
    //     children: [{
    //         path: '/rx_home_en',
    //         name: 'RongXinHome',
    //         component: () =>
    //             import ('@/views/other/RongXinHomeEn.vue'),
    //     }, ]
    // },
    // {
    //     path: '/jnl_home',
    //     name: 'JnlIndex',
    //     component: () =>
    //         import ('@/views/other/JnlIndex.vue'),
    //     redirect: '/jnl_home',
    //     children: [{
    //         path: '/jnl_home',
    //         name: 'JnlHome',
    //         component: () =>
    //             import ('@/views/other/JnlHome.vue'),
    //     }, ]
    // },
    // {
    //     path: '/jnl_home_en',
    //     name: 'JnlIndex',
    //     component: () =>
    //         import ('@/views/other/JnlIndex.vue'),
    //     redirect: '/jnl_home_en',
    //     children: [{
    //         path: '/jnl_home_en',
    //         name: 'JnlHome',
    //         component: () =>
    //             import ('@/views/other/JnlHomeEn.vue'),
    //     }, ]
    // },
    {
        path: '*',
        redirect: '/home'
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router