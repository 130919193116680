const state = {
    // userName: '',
    language: '' || sessionStorage.getItem('language'),
}
const mutations = { // 同步更改state
    // SET_USER_NAME(state, name) {
    //     state.userName = name
    // },
    setLanguage(state, val) {
        state.language = val;
        sessionStorage.setItem('language', val);
    },
}
const actions = { // 提交mutation, 里面可以包含异步操作
    // setUserName({ commit }, name) {
    //     commit('SET_USER_NAME', name)
    // }
}
export default {
    state,
    mutations,
    actions
}