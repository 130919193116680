// import user from './modules/user';

const getters = { // 获取最新的state
    // userName: state => state.user.userName,
    language: state => state.user.language || sessionStorage.getItem('language'),
}

// const getters = [user].map(item => {
//     Object.keys(user.state)
// });

export default getters